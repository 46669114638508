import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/macOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "macOS Software for your INSTAR IP Camera",
  "path": "/Software/macOS/EvoCam/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The macOS app EvoCam for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Evocam.png",
  "social": "/images/Search/P_SearchThumb_Evocam.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-macOS_EvoCam_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='macOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The macOS app EvoCam for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/macOS/EvoCam/' locationFR='/fr/Software/macOS/EvoCam/' crumbLabel="EvoCam" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <h2 {...{
      "id": "evocam",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#evocam",
        "aria-label": "evocam permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EvoCam`}</h2>
    <p>{`Evocam ist a IP Surveillance Solution Software for MacOSX from the company `}<a href="http://www.evological.com/" target="_blank" rel="noopener noreferrer">{`Evological`}</a>{`. In order to integrate your INSTAR IP camera in Evocam we recommend you to download the latest version from `}<a href="http://download.cnet.com/EvoCam/3000-2348_4-10187905.html" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`After you installed the newest version of Evocam please start the program, then open the preferences window.`}</p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_01.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Go to Preferences and click on Add Camera.`}</p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_02.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Now you can add cameras to the software by clicking on the + button and typing in their respective IP addresses. Please choose the Preset 1 for all VGA cameras. And Preset 3 for all HD camera models from INSTAR.`}</p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_03.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Now go back to the software main window, click on New Item - New Camera and choose the camera you created in the previous step.`}</p>
    <h3 {...{
      "id": "streaming-webserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#streaming-webserver",
        "aria-label": "streaming webserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Streaming Webserver`}</h3>
    <p>{`To activate the web server function, click the Streaming' button and check the input box. Create a user login and open the displayed Server Address in your default web browser. To access the web server from the internet, you can use the INSTAR DDNS address from your camera. Just point a Port Forwarding rule to your computer´s IP address inside your local network (instead of forwarding a port for your camera). The local IP address is the address displayed in the "Address" field for the web server above. The default web server port in Evocam is 8080.`}</p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_04.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Activate the Web Server and test the server Address with your web browser.`}</p>
    <h3 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording`}</h3>
    <p><img parentName="p" {...{
        "src": "./EvoCam_05.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Click on recording to activate and configure the Recording function.`}</p>
    <h3 {...{
      "id": "automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#automation",
        "aria-label": "automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automation`}</h3>
    <p><img parentName="p" {...{
        "src": "./EvoCam_06.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_07.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "./EvoCam_08.png",
        "alt": "EvoCam for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Evocam gives you plenty of Action triggers to fully automate your Surveillance.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      